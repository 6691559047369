@font-face {
  font-family: 'Beatrice';
  src: url('resources/fonts/Beatrice-Regular.eot');
  src: url('resources/fonts/Beatrice-Regular.eot?#iefix') format('embedded-opentype'),
      url('resources/fonts/Beatrice-Regular.woff2') format('woff2'),
      url('resources/fonts/Beatrice-Regular.woff') format('woff'),
      url('resources/fonts/Beatrice-Regular.ttf') format('truetype'),
      url('resources/fonts/Beatrice-Regular.svg#Beatrice-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.navbar {
  background-color: white; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 7%;
  padding-right: 7%;
}

.navbar-heart {
  height: 48px;
  width: 78px;
  background: url(https://web.socialprintstudio.com/img/icons/arch-logo.svg) no-repeat;
  overflow: hidden;
  background-size: 78px;
  margin: 8px 0 8px 0;
  /* margin-left: 17.5%; */
  background-position: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar div {
  flex: 1;
}

.navbar .end {
  justify-content: flex-end;
}

.App {
  
}

body {
  font-family: 'Beatrice', sans-serif;
  background-color: #F3F1F0;
}

.container{
  min-width: 300px;
  width: 90%;
  max-width: 420px; 
  margin: 0 auto;
  /* margin-left: 7%; */
  /* margin-right: 7%; */
}

.navbar .title {
  display: contents;
  height: 64px;
  line-height: 32px;
  color: #333333;
  font-size: 11px;
  font-family: 'Beatrice', sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h1,h2,h3,h4 {
  text-align: center;
}

button{
  color: #FFFFFF;
  display: inline-block;
  background-color: #719483;
  opacity: 1;
  text-transform: uppercase;
  padding: 5px 17px;
  font-size: 12px;
  font-weight: 100;
  line-height: 36px;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: 500;
  border-radius: 24px;
  border-width: 0px;
  margin-top: 30px;
  font-family: 'Beatrice';
}

button:disabled,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
  border:1px solid #ccc;
}

.errorLabel {
  color: tomato;
  font-size: 12px;
}

.identifyDiv{
  max-width: 450px;
  margin: 50px auto;
  text-align: center;
}

.identifyBookDiv{
  max-width: 450px;
  margin: 50px auto;
  text-align: left;
}


.identifyEmailLabel{
  font-family: 'Orpheus Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  /* identical to box height, or 136% */

  text-align: center;

  /* Warm Neutrals/Carbon */

  color: #333333;
}
.identifyBody{
  font-family: 'Beatrice';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Warm Neutrals/Carbon */

  color: #333333;
}

.identifyInput{
  all: unset;
  width: 60%;
  background: #FFFFFF;
  border-radius: 32px;
  text-align: left;
  font-family: 'Beatrice';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-left:4%;
}

.indentifyInputDiv{
  background: #FFFFFF;
  border-radius: 26px;
  position: absolute;
  height: 48px;
  min-width: 320px;
  width: 90%;
  max-width: 400px;
  /* margin-left: -225px;
  left: 70%; */
}

.identifyButton{
  margin-top: 0px;
  background: #719483;
  border-radius: 26px;
  width: 36%;
  font-size: 12px;
  color: #F3F1F0;
  padding: 6px;
}
.identifyCheckbox{
  margin-top: 60px;
  accent-color: #719483;
  border-radius: 2px;
}
.identifyCheckboxInput{
  vertical-align: middle;
}
.identifyCheckboxLabel{
  margin-left: 5px;
  font-family: 'Beatrice';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.backToSiteButton{
  background: #CC9B83;
  border-radius: 32px;
}
.savedProjectButtonsDiv{
  display: flex;
  justify-content:center;
  gap: 30px;
}
.savedProjectSpan{
  font-family: 'Beatrice';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #333333;
}

a:-webkit-any-link {
  color: #333333;
  background-color: transparent;
}

@media screen and (max-width: 420px) {
    
  button{
    padding: 5px 7px;
  }
}